; (function (a3ns, $) {

    if (!a3ns) throw new Error('Le namespace "a3ns" n\'est pas correctement initialisé');
    if (!$) throw new Error('jQuery n\'est pas chargé')

    /* Add helper */
    /* Modal */
    a3ns.helper.modal = {
        root: 'body',
        element: null,
        inner: null,
        open: false,
        exist: function () {
            return this.element != null;
        },
        create: function () {
            var modal = this;

            modal.inner = document.createElement('div');
            modal.inner.className = 'inner';

            modal.element = document.createElement('div');
            modal.element.className = 'modal';

            modal.element.appendChild(modal.inner);

            modal.element.addEventListener('click', function (e) {
                if (e.target != modal.element && !e.target.classList.contains('closer')) return;
                modal.destroy.call(modal);
            })
        },
        show: function (content, callback) {
            var modal = this;
            /* Check if modal DOM element exist */
            if (!this.exist()) this.create();
            /* Append content */
            modal.inner.innerHTML = content;
            /* Append element */
            document.querySelector(modal.root).appendChild(modal.element);
            /* Callback */
            if (callback) callback();
            /* Add class */
            setTimeout(function () {
                modal.element.classList.add('show');
            }, 10)

        },
        destroy: function () {
            var modal = this;
            modal.element.addEventListener('transitionend', function (e) {
                modal.element.parentNode.removeChild(modal.element);
                modal.element = null;
                modal.inner = null;
            });
            modal.element.classList.remove('show');
        }
    };

})(window.a3ns, jQuery);