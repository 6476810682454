;
(function (win, $) {

  /* Init namespace */
  win.a3ns = {
    template: {},
    helper: {},
    $htmlBody: $('html,body')
  };

})(window, jQuery);