;
(function (a3ns, $) {

	if (!a3ns) throw new Error('Le namespace "a3ns" n\'est pas correctement initialisé');
	if (!$) throw new Error('jQuery n\'est pas chargé')

	/* Vars */
	var $callButton = $('.call-button'),
		$topButton = $('.top-button'),
		$phoneButton = $('.phone-button'),
		$contactButton = $('.intro .button'),
		$submitBottomButton = $('.meet-us-form'),
		$submitTopButton = $('.recall'),
		$inputTel = $('input[name="phone"]'),
		$inputName = $('input[name="name"]');


	/* Manage display */
	var offset = 700;
	$(window).on('scroll', function (e) {
		var scrollState = window.scrollY,
			isShown = $callButton.hasClass('show');
		if (scrollState > offset && !isShown) {
			$callButton.addClass('show');
			$phoneButton.addClass('show');
			$topButton.addClass('show');
		} else if (scrollState <= offset && isShown) {
			$callButton.removeClass('show');
			$phoneButton.removeClass('show');
			$topButton.removeClass('show');
		}
	}).trigger('scroll');

	/* Click action */
	var goToForm = function (e) {
		e.preventDefault();
		/* vars */
		var targetId = this.getAttribute('href'),
			$target = $(targetId),
			top = $target.offset().top;
		/* Actions */
		a3ns.$htmlBody.animate({
			scrollTop: top
		}, 300);
	};

	/* Form submition */
	var sendForm = function (e) {
		e.preventDefault();
		/* Recaptcha */
		var widgetId = $(this).data('recaptchaId');
		if (window.grecaptcha) {
			grecaptcha.reset(widgetId);
			grecaptcha.execute(widgetId);
		};
	},
		postRecaptchaSubmit = function () {

			/* Var */
			var form = this,
				donnees = $(form).serializeArray();
			/* Actions */
			$.ajax({
				method: "POST",
				url: "php/sendMail.php",
				data: {
					"resp": donnees
				}
			}).done(function (response) {

				if (response == 1) {
					/* Display modal */
					a3ns.helper.modal.show($('#Modal').html());
					/* Clean form */
					form.reset();
				}

			});

		};


	/* Recaptcha callback */
	window.grecaptchaCallabck = function () {
		$(".g-recaptcha").each(function () {
			var $el = $(this),
				recaptchaId = grecaptcha.render($el.attr("id"), {
					"sitekey": '6LfAcMAUAAAAAHkZC6CtS5tCWDHYmlpqPoUhBxPs'
				});
			$el.closest('form').data('recaptchaId', recaptchaId);
		});
	};
	window.recallSubmit = function () {
		postRecaptchaSubmit.call(document.querySelector('#RecallForm'));
	};
	window.meetUsSubmit = function () {
		postRecaptchaSubmit.call(document.querySelector('#MeetUsForm'));
	};


	/* KeyPress Input */
	var onlyLetters = function (e) {
		var value = String.fromCharCode(e.which);
		var pattern = new RegExp(/[A-Za-zÀ-ÿ]|[\s-]/i);
		return pattern.test(value);
	};

	var onlyNumbers = function (e) {
		var value = String.fromCharCode(e.which);
		var pattern = new RegExp(/[0-9]|[\+]/i);
		return pattern.test(value);
	};

	/* Events */
	$callButton.on('click', goToForm);
	$contactButton.on('click', goToForm);
	$submitBottomButton.on('submit', sendForm);
	$submitTopButton.on('submit', sendForm);
	$inputName.bind('keypress', onlyLetters);
	$inputTel.bind('keypress', onlyNumbers);
	$topButton.on('click', function () {
		a3ns.$htmlBody.animate({
			scrollTop: 0
		}, 300);
	});


})(window.a3ns, jQuery);